import { api } from './configs/axiosConfigs';

export const OrderAPI = {
  createOrder: async function (payload) {
    const response = await api.request({
      url: '/me/orders/',
      method: 'POST',
      data: payload,
    });
    return [response.data, response.status];
  },
  getOrders: async function () {
    const response = await api.request({
      url: '/me/orders/',
      method: 'GET',
    });

    return response.data;
  },
  getOrder: async function (id) {
    const response = await api.request({
      url: `/me/orders/${id}`,
      method: 'GET',
    });

    return response?.data;
  },
  updateOrder: async function (id) {
    try {
      const response = await api.request({
        url: `/me/orders/${id}/cancel`,
        method: 'PUT',
      });

      return response.data;
    } catch (error) {
      return null;
    }
  },
};
