import React, { useState, useRef, useEffect, useContext } from 'react';
import useWebSocket from 'react-use-websocket';
import { UserContext } from '../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../../components/Panel';
import {
  Paper,
  Box,
  TextField,
  IconButton,
  Drawer,
  Grid,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import BottomPanel from '../../components/BottomPanel';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GroupButtons from '../../components/GroupButtons';
import { TitleLabel, SubTitleLabel } from '../../components/Label';
import CloseIcon from '@mui/icons-material/Close';
import NumberFormat from 'react-number-format';
import { ProductAPI } from '../../apis/ProductAPI';
import { OrderAPI } from '../../apis/OrderAPI';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import {
  Round,
  CountDecimals,
  ThousandSeparator,
  ReplaceCommaToNumber,
  ReplaceCommaToInt,
  ReplaceStringToInt,
  ReplaceStringToNumber,
  CheckLeadingZero,
  InputCursorPosition,
} from '../../utils/CoinNumberFormat';
import Dialog from '../../components/Dialog';
import Invoice from '../../components/trade/Invoice';
import { UserAPI } from '../../apis/UserAPI';
import validator from '../account/validator';
import StyledButton from '../../components/StyledButton';
import humps from 'humps';
import useInvoice from '../../hooks/useInvoice';

const priceRange = [0.25, 0.5, 0.75, 1];

const selectedBorder = {
  borderColor: '#FF8E15',
  borderWidth: '2px',
};

const sxSelected = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': selectedBorder,
    '&:hover fieldset': selectedBorder,
    '&.Mui-focused fieldset': selectedBorder,
    color: '#FF8F08',
  },
};

const sxUnselected = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#eee',
      borderWidth: '2px',
      color: '#000',
    },
  },
};

export default function SellPage() {
  const navigate = useNavigate();
  const { user, setLoading } = useContext(UserContext);
  const { getCarrierType, getCarrierCode } = useInvoice();
  const params = useParams();
  const productId = params.coin;
  const initData = useRef(false);
  const productRef = useRef(null);
  const [errors] = useState({});
  const [expired, setExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);
  const expiredIdRef = useRef(null);
  const timeLeftIdRef = useRef(false);
  const lastPriceRef = useRef(0);
  const lastSizeRef = useRef(0);
  const [product, setProduct] = useState(null);
  const [sellPrice, setSellPrice] = useState(0);
  const [size, setSize] = useState(0);
  const sizeRef = useRef(0);
  const priceRef = useRef(0);
  const sellPriceRef = useRef(0);
  // const originalPriceRef = useRef(0);
  // const exchangeRateRef = useRef(0);
  const websocketPriceRef = useRef(0);
  const [sizeFocus, setSizeFocus] = useState(true);
  const [price, setPrice] = useState(0);
  const [priceFocus, setPriceFocus] = useState(false);
  const inputSizeRef = useRef(null);
  const inputPriceRef = useRef(null);
  const [disableButton, setDisableButton] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [alert, setAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);
  const billInfoDataRef = useRef(null);
  const carrierRef = useRef(getCarrierType('A'));
  const phoneBarcodeRef = useRef(null);
  const citizenDigitalRef = useRef(null);
  const cityRef = useRef(null);
  const districtRef = useRef(null);
  const addressRef = useRef(null);
  const doneeRef = useRef(null);
  const doneeCodeRef = useRef(null);
  const zipCodeRef = useRef(null);
  const billNameRef = useRef(null);
  // const invoiceTabRef = useRef(0);
  const [invoiceTab, setInvoiceTab] = useState(0);
  const [invoiceInputFocus, setInvoiceInputFocus] = useState(false);
  const [percentageButton, setPercentageButton] = useState(false);
  const realtimePricesRef = useRef(null);
  const [lastPrice, setLastPrice] = useState(0);
  const [lastSize, setLastSize] = useState(0);
  const [lastRealtime, setLastRealtime] = useState({});

  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => true,
    reconnectInterval: 3000,
    queryParams: {
      token: getWebsocketToken(),
    },
  });
  const handleBack = () => {
    navigate(-1);
  };
  const balance = useRef(0);

  useEffect(() => {
    async function fetchPaymentInfo() {
      const paymentInfo = await UserAPI.getPaymentInfo(productId, 'sell');
      balance.current = paymentInfo[0].remainingAmount.toFixed(8);
      fetchProductData();
    }

    async function fetchProductData() {
      const product = await ProductAPI.getProduct(productId);
      realtimePricesRef.current = product?.realtimePrice;
      productRef.current = product;
      setProduct(product);
      sellPriceRef.current =
        productRef.current.realtimePrice['balance'].sellPrice;
      const defaultSize = 100 / sellPriceRef.current;

      if (ReplaceCommaToNumber(balance.current) <= defaultSize) {
        sizeRef.current = balance.current;
        setSize(balance.current);

        const newTWDPrice =
          ReplaceCommaToNumber(balance.current) * sellPriceRef.current;
        const roundedPrice = Round(newTWDPrice);
        priceRef.current = roundedPrice;
        setPrice(ThousandSeparator(roundedPrice));
        setPercentageButton(true);
      } else {
        const roundedSize = Round(
          defaultSize,
          CountDecimals(product?.baseIncrement),
        );
        sizeRef.current = roundedSize;
        setSize(roundedSize);
        priceRef.current = 100;
        setPrice(100);
        setPercentageButton(false);
      }

      validateOrder();
    }

    async function fetchBillInfoData() {
      const userData = await UserAPI.getBillInfo();
      billInfoDataRef.current = userData;
      if (userData.isDonee) {
        setInvoiceTab(1);
      }
      if (userData.carrierType) {
        carrierRef.current = getCarrierType(userData.carrierType);
        phoneBarcodeRef.current = userData.phoneBarcode;
        citizenDigitalRef.current = userData.citizenDigitalCertificate;
      }
      cityRef.current = userData.billAddrCity.replace(/台/g, '臺');
      districtRef.current = userData.billAddrDistrict.replace(/台/g, '臺');
      addressRef.current = userData.billAddr;
      doneeRef.current = userData.doneeName;
      doneeCodeRef.current = userData.doneeCode;
      billNameRef.current = userData.billInfoName;
    }

    if (initData.current === false) {
      setInvoiceTab(0);
      initData.current = true;
      fetchBillInfoData();
      fetchPaymentInfo();
    }
    if (productRef.current) {
      setSellPrice(productRef.current.realtimePrice['balance'].sellPrice);
    }
  }, [productId]);

  // useEffect(() => {
  //   if (product && !openDrawer) {
  //     const originalPriceData = (paymentType) =>
  //       product?.realtimePrice[paymentType]?.sellOriginalPrice;
  //     originalPriceRef.current = originalPriceData('balance');

  //     const exchangeRateData = (paymentType) =>
  //       product?.realtimePrice[paymentType]?.sellExchangeRate;
  //     exchangeRateRef.current = exchangeRateData('balance');

  //     const sellPriceData = (paymentType) =>
  //       product?.realtimePrice[paymentType]?.sellPrice;
  //     sellPriceRef.current = sellPriceData('balance');
  //   }
  // }, [product, openDrawer, lastMessage]);

  useEffect(() => {
    if (lastMessage) {
      const realtimePrices = JSON.parse(lastMessage.data);
      if (realtimePrices['id'] && realtimePrices['status']) {
        return;
      }
      realtimePricesRef.current = realtimePrices[product.id];

      const updateProductRealtimePrice = (realtimePrice) => {
        const newProduct = { ...product };
        newProduct.realtimePrice = humps.camelizeKeys(realtimePrice);
        setProduct(newProduct);
        productRef.current = newProduct;
      };

      if (product) {
        // to avoid using init product price when payment method change
        updateProductRealtimePrice(realtimePrices[productId]);
      }

      const priceData = realtimePrices[productId]['balance'];
      setSellPrice(priceData.sell_price);
      websocketPriceRef.current = priceData.sell_price;

      if (!openDrawer) {
        const renewSize = () => {
          const newSize =
            ReplaceCommaToInt(priceRef.current) / sellPriceRef.current;
          const roundedSize = Round(
            newSize,
            CountDecimals(productRef.current?.baseIncrement),
          );
          sizeRef.current = roundedSize;
          setSize(roundedSize);
          validateOrder();
        };

        const renewPrice = () => {
          const newPrice =
            ReplaceCommaToNumber(sizeRef.current) * sellPriceRef.current;
          const roundedPrice = Round(newPrice);
          priceRef.current = roundedPrice;
          setPrice(ThousandSeparator(roundedPrice));
          validateOrder();
        };
        sellPriceRef.current = websocketPriceRef.current;

        if (sizeFocus) renewPrice();
        if (priceFocus) renewSize();
      }
    }
  }, [lastMessage]);

  async function fetchPriceRangeData(index) {
    setLoading(true);
    const newSize = balance.current * priceRange[index];
    const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
    sizeRef.current = roundedSize;

    priceRef.current = Round(
      balance.current * priceRange[index] * sellPriceRef.current,
    );
    setSize(roundedSize);
    setPrice(
      ThousandSeparator(
        Round(balance.current * priceRange[index] * sellPriceRef.current),
      ),
    );
    validateOrder();
    setLoading(false);
  }

  const checkBillInfo = () => {
    let alert = null;
    if (invoiceTab === 1) {
      if (!doneeCodeRef.current || !doneeRef.current) {
        alert = '請選擇捐贈單位';
      }
    } else {
      if (getCarrierCode(carrierRef.current) === 'P') {
        if (!cityRef.current || !districtRef.current || !addressRef.current) {
          alert = '請輸入通訊地址';
        }
      }
      if (getCarrierCode(carrierRef.current) === 'B') {
        if (!validator.isPhoneBarcode(phoneBarcodeRef.current, errors)) {
          alert = errors.barcode;
        }
      }
      if (getCarrierCode(carrierRef.current) === 'C') {
        if (!validator.isCitizenDigital(citizenDigitalRef.current, errors)) {
          alert = errors.citizenDigital;
        }
      }
    }

    if (alert) {
      alertTitle.current = '請輸入完整發票資料';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
    } else {
      setExpired(false);
      expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
      timeLeftIdRef.current = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      setOpenDrawer(true);
      lastSizeRef.current = sizeRef.current;
      lastPriceRef.current = priceRef.current;
      setLastSize(sizeRef.current);
      setLastPrice(priceRef.current);
      setLastRealtime(realtimePricesRef.current);
      // lastSellPriceRef.current = sellPriceRef.current;
    }
  };

  async function createOrder() {
    const payload = {
      productId: productId,
      type: 'limit',
      side: 'sell',
      size: ReplaceCommaToNumber(lastSize),
      fund: ReplaceCommaToInt(lastPrice),
      payment: 'balance',
      totalPrice:
        lastRealtime?.balance?.sell_price ??
        lastRealtime?.balance?.sellPrice ??
        null, //last
      placeOrderType: sizeFocus ? 'quantity' : 'amount',
      exchangeRate:
        lastRealtime?.balance?.sell_exchange_rate ??
        lastRealtime?.balance?.sellExchangeRate ??
        null,
      originalPrice:
        lastRealtime?.balance?.sell_original_price ??
        lastRealtime?.balance?.sellOriginalPrice ??
        null,
    };

    const [order, statusCode] = await OrderAPI.createOrder(payload).catch(
      (error) => {
        setShowAlert(true);
        alertTitle.current = '發生錯誤';
        alertMessage.current = error.response.data.error.message;
        alertActionLabel.current = '確定';
        setDisableButton(false);
        handleExpired();
      },
    );

    if (order !== null && statusCode === 200) {
      if (getCarrierCode(carrierRef.current) === 'A') {
        const invoiceDetail = {
          phoneNumber: user.phoneNumber,
          email: billInfoDataRef.current.billInfoEmail,
          address: cityRef.current + districtRef.current + addressRef.current,
        };
        order['invoiceDetail'] = invoiceDetail;
      }
      setLoading(true);
      navigate('/tradeDone', { state: { order } });
      setAlert(false);
    }
    setDisableButton(false);
  }

  async function updateBillInfo() {
    let payload = {};
    let alert = null;
    let needUdate = false;
    if (invoiceTab === 1) {
      if (
        billInfoDataRef.current.doneeCode !== doneeCodeRef.current ||
        billInfoDataRef.current.isDonee !== true
      ) {
        payload = {
          donee_code: doneeCodeRef.current,
          is_donee: true,
        };
        needUdate = true;
      }
    } else {
      if (billInfoDataRef.current.isDonee === true) {
        payload = {
          is_donee: false,
        };
        needUdate = true;
      }

      if (billInfoDataRef.current.billAddrCity !== cityRef.current) {
        payload['bill_addr_city'] = cityRef.current;
        needUdate = true;
      }

      if (billInfoDataRef.current.billAddrDistrict !== districtRef.current) {
        payload['bill_addr_zip_code'] = zipCodeRef.current;
        payload['bill_addr_district'] = districtRef.current;
        needUdate = true;
      }

      if (billInfoDataRef.current.billAddr !== addressRef.current) {
        payload['bill_addr'] = addressRef.current;
        needUdate = true;
      }

      if (
        billInfoDataRef.current.carrierType !==
          getCarrierCode(carrierRef.current) ||
        phoneBarcodeRef.current !== billInfoDataRef.current.phoneBarcode ||
        citizenDigitalRef.current !==
          billInfoDataRef.current.citizenDigitalCertificate
      ) {
        payload['carrier_type'] = getCarrierCode(carrierRef.current);
        if (getCarrierCode(carrierRef.current) === 'B') {
          payload['phone_barcode'] = phoneBarcodeRef.current;
        }
        if (getCarrierCode(carrierRef.current) === 'C') {
          payload['citizen_digital_certificate'] = citizenDigitalRef.current;
        }
        needUdate = true;
        payload['is_donee'] = false;
      }
    }
    if (alert) {
      alertTitle.current = '請輸入完整發票資料';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
      setDisableButton(false);
    } else {
      createOrder();
      if (needUdate) {
        await UserAPI.updateBillInfo(payload);
      }
    }
    setOpenDrawer(false);
  }

  async function tradeDone() {
    setDisableButton(true);
    // setOpenDrawer(false);
    updateBillInfo();
  }

  const handleAlertAction = () => {
    setShowAlert(false);
  };

  const handleSizeChange = (event) => {
    setSizeFocus(true);
    setPriceFocus(false);
    setInvoiceInputFocus(false);
    setPercentageButton(false);
    event.target.value = CheckLeadingZero(
      ReplaceStringToNumber(event.target.value),
    );
    // InputCursorPosition(event, inputSizeRef);

    /*符合幣值最小位數長度*/

    if (event.target.value >= ReplaceCommaToNumber(balance.current)) {
      sizeRef.current = ReplaceCommaToNumber(balance.current);
      setSize(balance.current);
    } else {
      if (event.target.value < 1) {
        if (
          event.target.value.indexOf('.') > 0 &&
          event.target.value.charAt(event.target.value.length - 1) !== '.' &&
          Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
        ) {
          const roundedSize = Round(
            ReplaceCommaToNumber(event.target.value),
            CountDecimals(product?.baseIncrement),
          );
          sizeRef.current = roundedSize;
          setSize(roundedSize);
        } else {
          if (
            Number(event.target.value.charAt(event.target.value.length - 1)) !==
            0
          ) {
            sizeRef.current = event.target.value;
          } else {
            sizeRef.current = Round(
              ReplaceCommaToNumber(event.target.value),
              CountDecimals(product?.baseIncrement),
            );
          }
          setSize(event.target.value);
        }
      } else {
        if (
          event.target.value.indexOf('.') > 0 &&
          event.target.value.charAt(event.target.value.length - 1) !== '.'
        ) {
          if (
            Number(event.target.value.charAt(event.target.value.length - 1)) !==
            0
          ) {
            const roundedSize = Round(
              ReplaceCommaToNumber(event.target.value),
              CountDecimals(product?.baseIncrement),
            );
            sizeRef.current = roundedSize;
            setSize(roundedSize);
          } else {
            setSize(event.target.value);
            sizeRef.current = event.target.value;
          }
        } else {
          sizeRef.current = event.target.value;
          setSize(event.target.value);
        }
      }
    }

    const newTWDPrice = sizeRef.current * sellPriceRef.current;
    const roundedPrice = Round(newTWDPrice);
    priceRef.current = roundedPrice;
    setPrice(ThousandSeparator(roundedPrice));

    validateOrder();
  };
  const handleTWDChange = (event) => {
    setSizeFocus(false);
    setPriceFocus(true);
    setInvoiceInputFocus(false);
    setPercentageButton(false);
    event.target.value = CheckLeadingZero(
      ReplaceStringToInt(event.target.value),
    );

    InputCursorPosition(event, inputPriceRef);

    const newSize = event.target.value / sellPriceRef.current;

    if (newSize >= ReplaceCommaToNumber(balance.current)) {
      sizeRef.current = balance.current;
      setSize(balance.current);

      const newTWDPrice =
        ReplaceCommaToNumber(balance.current) * sellPriceRef.current;
      const roundedPrice = Round(newTWDPrice);
      priceRef.current = roundedPrice;
      setPrice(ThousandSeparator(roundedPrice));
    } else {
      const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
      sizeRef.current = roundedSize;
      setSize(roundedSize);
      priceRef.current = ReplaceCommaToNumber(event.target.value);
      setPrice(ThousandSeparator(event.target.value));
    }

    validateOrder();
  };
  const handleReset = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    setSizeFocus(true);
    setPriceFocus(false);
    setOpenDrawer(false);
    setDisableButton(false);
  };

  const handleSelectPriceRange = (index) => {
    setPercentageButton(true);
    fetchPriceRangeData(index);
  };

  const handleTradeDone = () => {
    tradeDone();
  };

  const renewSize = () => {
    const newSize = ReplaceCommaToInt(priceRef.current) / sellPriceRef.current;
    const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
    sizeRef.current = roundedSize;
    setSize(roundedSize);
    validateOrder();
  };

  const renewPrice = () => {
    const newPrice =
      ReplaceCommaToNumber(sizeRef.current) * sellPriceRef.current;
    const roundedPrice = Round(newPrice);
    priceRef.current = roundedPrice;
    setPrice(ThousandSeparator(roundedPrice));
    validateOrder();
  };

  const handleFocus = () => {
    if (priceFocus === true) {
      setPriceFocus(false);
      setSizeFocus(true);
      inputSizeRef.current.focus();
    }
    if (sizeFocus === true) {
      setPriceFocus(true);
      setSizeFocus(false);
      inputPriceRef.current.focus();
    }
    if (sizeFocus === false && priceFocus === false) {
      setSizeFocus(true);
      inputSizeRef.current.focus();
    }
    setInvoiceInputFocus(false);
  };

  const resetTimeout = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    lastSizeRef.current = sizeRef.current;
    lastPriceRef.current = priceRef.current;
    // lastSellPriceRef.current = sellPriceRef.current;
  };

  const handleExpired = () => {
    resetTimeout();
    // setOpenDrawer(false);
    // if (websocketPriceRef.current !== 0) {
    //   sellPriceRef.current = websocketPriceRef.current;
    // }
    if (priceFocus) {
      renewSize();
      setPriceFocus(true);
      setSizeFocus(false);
      inputPriceRef.current.focus();
    } else {
      renewPrice();
      setSizeFocus(true);
      setPriceFocus(false);
      inputSizeRef.current.focus();
    }
  };

  const handleRenewPrice = () => {
    resetTimeout();
    // if (websocketPriceRef.current !== 0) {
    //   sellPriceRef.current = websocketPriceRef.current;
    // }
    //if (priceFocus) renewSize();
    //if (sizeFocus) renewPrice();
    //setLastPrice(price);
    //setLastSize(size);
    //setLastRealtime(realtimePricesRef.current);
    expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
    timeLeftIdRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);
    // const originalPriceData = (paymentType) =>
    //   product?.realtimePrice[paymentType]?.sellOriginalPrice;
    // originalPriceRef.current = originalPriceData('balance');

    // const exchangeRateData = (paymentType) =>
    //   product?.realtimePrice[paymentType]?.sellExchangeRate;
    // exchangeRateRef.current = exchangeRateData('balance');

    // const sellPriceData = (paymentType) =>
    //   product?.realtimePrice[paymentType]?.sellPrice;
    // sellPriceRef.current = sellPriceData('balance');
    if (priceFocus) {
      const newSize =
        ReplaceCommaToInt(priceRef.current) /
        realtimePricesRef?.current?.balance.sell_price;

      const roundedSize = Round(newSize, CountDecimals(product?.baseIncrement));
      setLastPrice(priceRef.current);
      setLastSize(roundedSize);
    }
    if (sizeFocus) {
      const newPrice =
        ReplaceCommaToNumber(sizeRef.current) *
        realtimePricesRef?.current?.balance.sell_price;

      const roundedPrice = Round(newPrice);
      setLastPrice(roundedPrice);
      setLastSize(sizeRef.current);
    }
    setLastRealtime(realtimePricesRef.current);
    validateOrder();
  };

  const navigation = () => {
    return (
      <NavigationBar
        title={'賣出 ' + product?.baseCurrencyId}
        leftButtonHandler={handleBack}
      />
    );
  };
  const Icon = styled('img')({
    maxHeight: 40,
    maxWidth: 40,
  });
  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };
  const validateOrder = () => {
    if (
      sizeRef.current === 0 ||
      priceRef.current === 0 ||
      priceRef.current > productRef.current.maxFund ||
      priceRef.current < productRef.current.minFund ||
      sizeRef.current < 0 ||
      priceRef.current < 0
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  const handleClosedDrawer = () => {
    setOpenDrawer(false);
    handleExpired();
  };

  const titleLabel = () => {
    return (
      <Box marginTop="23px">
        <TitleLabel
          fontSize="15px"
          fontWeight="bold"
          marginTop="25px"
          align="center">
          1&nbsp;{product?.baseCurrencyId}&nbsp;&nbsp;≈&nbsp;&nbsp;
          <NumberFormat
            value={sellPrice}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={sellPrice < 100 ? 2 : 0}
            fixedDecimalScale={true}
          />
          &nbsp;TWD
        </TitleLabel>
      </Box>
    );
  };
  const currentBalance = () => {
    return (
      <Box marginTop="23px" marginLeft="16px" marginRight="16px">
        <Panel>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ m: '20px' }}>
            <TitleLabel fontSize="15px" fontWeight="medium">
              持有{product?.baseCurrencyId}數量
            </TitleLabel>
            <TitleLabel fontSize="17px" fontWeight="bold">
              {balance.current}&nbsp;{product?.baseCurrencyId}
            </TitleLabel>
          </Box>
        </Panel>
      </Box>
    );
  };
  const inputLabel = (text, px, color, align) => {
    return (
      <TitleLabel fontSize={px} color={color} align={align}>
        {text}
      </TitleLabel>
    );
  };
  const tipLabel = () => {
    return (
      <Box marginTop="15px" marginLeft="22px">
        <SubTitleLabel fontSize="13px" fontWeight="regular" color="#6C757D">
          {'賣出金額　Max. NT$ ' +
            ThousandSeparator(Number(product?.maxFund)) +
            '　Min. NT$ ' +
            ThousandSeparator(Number(product?.minFund))}
        </SubTitleLabel>
      </Box>
    );
  };
  const inputPanel = () => {
    const percent = ['25%', '50%', '75%', 'Max'];
    return (
      <Box margin="16px" marginTop="24px">
        <Panel sx={{ borderRadius: '12px' }}>
          <Box marginTop="28px">
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center">
              <Grid item xs={2}>
                {inputLabel('賣出', '15px', '#212529', 'right')}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  hiddenLabel
                  autoComplete="off"
                  fullWidth
                  sx={sizeFocus ? sxSelected : sxUnselected}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: 24,
                    },
                  }}
                  inputRef={inputSizeRef}
                  autoFocus={true}
                  onClick={() => {
                    setSizeFocus(true);
                    setPriceFocus(false);
                    setInvoiceInputFocus(false);
                  }}
                  defaultValue={0}
                  onChange={handleSizeChange}
                  value={size}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton disabled={true}>
                  <Icon
                    sx={{
                      maxHeight: 40,
                      maxWidth: 40,
                    }}
                    src={`/images/${product?.baseCurrencyId}.png`}
                  />
                </IconButton>
              </Grid>

              <Grid item xs={2}>
                {inputLabel(product?.baseCurrencyId, '17px', '#212529', 'left')}
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={2}>
              　　
            </Grid>

            <Grid item xs={6}>
              　
            </Grid>

            <Grid item xs={2}>
              <IconButton onClick={handleFocus}>
                <ImportExportIcon
                  style={{ fontSize: 40 }}
                  sx={{
                    color: (theme) => theme.palette.common.black,
                  }}
                />
              </IconButton>
            </Grid>

            <Grid item xs={2}>
              　　
            </Grid>
          </Grid>
          <Box>
            <Box>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center">
                <Grid item xs={2}>
                  {inputLabel('獲得', '15px', '#212529', 'right')}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    hiddenLabel
                    autoComplete="off"
                    fullWidth
                    sx={priceFocus ? sxSelected : sxUnselected}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                        fontSize: 24,
                      },
                    }}
                    inputRef={inputPriceRef}
                    defaultValue={0}
                    onChange={handleTWDChange}
                    value={price}
                    onClick={() => {
                      setSizeFocus(false);
                      setPriceFocus(true);
                      setInvoiceInputFocus(false);
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton disabled={true}>
                    <Icon
                      sx={{
                        maxHeight: 40,
                        maxWidth: 40,
                      }}
                      src="../images/TWD.png"
                    />
                  </IconButton>
                </Grid>

                <Grid item xs={2}>
                  {inputLabel('TWD', '17px', '#212529', 'left')}
                </Grid>
              </Grid>
            </Box>
            <Box marginTop="24px" marginBottom="16px">
              <GroupButtons
                defaultSelection={3}
                items={percent}
                selectionHandler={handleSelectPriceRange}
                selected={percentageButton}
              />
            </Box>
          </Box>
        </Panel>
      </Box>
    );
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disabled={disableButton}
          disableElevation
          onClick={checkBillInfo}>
          {'賣出 ' + size + ' ' + product?.baseCurrencyId}
        </StyledButton>
      </BottomPanel>
    );
  };
  const sxBottomDrawer = {
    height: '320px',
    padding: '16px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  };
  const bottomDrawer = () => {
    return (
      <Box marginTop="23px">
        <Drawer
          open={openDrawer}
          anchor="bottom"
          PaperProps={{
            elevation: 0,
            style: { backgroundColor: 'transparent' },
          }}>
          <Paper sx={sxBottomDrawer} elevation={3}>
            <Box display="flex" alignItems="center">
              <Box sx={{ flex: 1 }} marginLeft="10vw">
                <TitleLabel
                  fontSize="17px"
                  fontWeight="bold"
                  textAlign="center">
                  確認交易資訊
                </TitleLabel>
              </Box>
              <IconButton onClick={handleClosedDrawer}>
                <CloseIcon
                  style={{ fontSize: 24 }}
                  sx={{
                    color: (theme) => theme.palette.common.black,
                  }}
                />
              </IconButton>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="24px">
              <TitleLabel fontSize="15px">賣出數量：</TitleLabel>
              <TitleLabel fontSize="15px">
                {lastSize} {product?.baseCurrencyId}
              </TitleLabel>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="8px">
              <TitleLabel fontSize="15px">獲得金額：</TitleLabel>
              <TitleLabel fontSize="15px">
                <NumberFormat
                  value={lastPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                />
              </TitleLabel>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="12px">
              <TitleLabel fontSize="13px" color="#696969">
                一旦點擊確定即表示您同意以上交易內容，若確認過程中因幣價波動過大，OP加密資產存摺保留取消此筆交易的權利。
              </TitleLabel>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="12px">
              {!expired ? (
                <TitleLabel fontSize="13px" color="#FF8F08">
                  價格有效時間 {timeLeft}
                </TitleLabel>
              ) : (
                ''
              )}
            </Box>
            {!expired ? (
              <Box display="flex" marginTop="12px">
                <StyledButton
                  variant="contained"
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                  }}
                  fullWidth
                  sx={{
                    height: '48px',
                  }}
                  onClick={handleReset}>
                  取消
                </StyledButton>
                <Box width="20px"></Box>
                <StyledButton
                  variant="contained"
                  fullWidth
                  onClick={handleTradeDone}>
                  確認
                </StyledButton>
              </Box>
            ) : (
              <Box display="flex" marginTop="12px">
                <StyledButton
                  variant="contained"
                  fullWidth
                  onClick={handleRenewPrice}
                  sx={{
                    height: '48px',
                  }}>
                  報價已失效，重新獲得報價
                </StyledButton>
              </Box>
            )}
          </Paper>
        </Drawer>
      </Box>
    );
  };

  const invoiceWording = () => {
    return (
      <>
        <SubTitleLabel>
          注意事項：
          <br />
          1. 下單成立後無法退款
          <br />
          2. 依法規定，個人發票一經開立，不得更改。
          <br />
        </SubTitleLabel>
        <SubTitleLabel>
          <span style={{ color: '#FF8F08' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;財政部電子發票整合服務平台
          </span>
        </SubTitleLabel>
      </>
    );
  };
  return (
    <>
      <Box sx={sxContainer}>
        {alert ? (
          <Alert severity="error">Something Wrong, please try again.</Alert>
        ) : (
          <></>
        )}
        {navigation() /* navigation bar */}
        {titleLabel() /* 幣值 */}
        {currentBalance() /* 持有數量 */}
        {inputPanel() /* 賣出 */}
        {tipLabel() /* 提示 */}
        {bottomPanel() /* 購買 / 賣出按鈕 */}
        {bottomDrawer() /* drawer */}
        <Invoice
          carrierRef={carrierRef}
          phoneBarcodeRef={phoneBarcodeRef}
          citizenDigitalRef={citizenDigitalRef}
          zipCodeRef={zipCodeRef}
          cityRef={cityRef}
          districtRef={districtRef}
          addressRef={addressRef}
          doneeCodeRef={doneeCodeRef}
          doneeRef={doneeRef}
          invoiceTab={invoiceTab}
          setInvoiceTab={setInvoiceTab}
          billNameRef={billNameRef}
          invoiceInputFocus={invoiceInputFocus}
          setInvoiceInputFocus={setInvoiceInputFocus}
        />
        {
          <Box marginLeft="16px" marginBottom="90px">
            {invoiceWording()}
            {bottomPanel() /* 購買 / 賣出按鈕 */}
            {bottomDrawer() /* drawer */}
          </Box>
        }
      </Box>

      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel={alertActionLabel.current}
        actionHandler={handleAlertAction}
      />
    </>
  );
}
