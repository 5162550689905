import React from 'react';
import { Box, Grid } from '@mui/material';
import Icon from '../Icon';
import { TitleLabel, SubTitleLabel } from '../Label';
import Separator from '../Separator';
import { AssetPriceNumberFormat } from '../../utils/CoinNumberFormat';
import Decimal from 'decimal.js';

const currencyLabel = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  SOL: 'Solana',
  USDT: 'Tether',
  USDC: 'USD Coin',
};

export default function AssetCoinCell({
  currencyId,
  balance,
  twdValue,
  initialTwdValue,
  isLastItem,
  onClick,
}) {
  const profitLossValue = twdValue - initialTwdValue;
  const profitLossPercentage =
    ((twdValue - initialTwdValue) / initialTwdValue) * 100;
  return (
    <>
      <Box marginLeft="16px" marginRight="16px" onClick={onClick}>
        <Grid container spacing="2px" height="56px">
          {/* 幣種 */}
          <Grid item xs={4}>
            <Box height="100%" display="flex" alignItems="center">
              <Icon
                width="30px"
                height="30px"
                src={`/images/${currencyId}.png`}
              />

              <Box marginLeft="8px">
                <TitleLabel fontSize="13px" fontWeight="regular">
                  {currencyId}
                </TitleLabel>

                <SubTitleLabel fontSize="9px" fontWeight="regular">
                  {currencyLabel[currencyId]}
                </SubTitleLabel>
              </Box>
            </Box>
          </Grid>

          {/* 數量 */}
          <Grid item xs={4}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center">
              <TitleLabel align="center" fontSize="13px" fontWeight="regular">
                {Number(Decimal(balance)).toFixed(8)}
              </TitleLabel>
            </Box>
          </Grid>

          {/* 台幣價值 */}
          <Grid item xs={4}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center">
              <TitleLabel align="center" fontSize="13px" fontWeight="regular">
                <AssetPriceNumberFormat value={twdValue} />
              </TitleLabel>
            </Box>
          </Grid>

          {/* 盈虧(TWD) */}
          {/* <Grid item xs={3}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center">
              <TitleLabel align="right" fontSize="13px" fontWeight="regular">
                <NumberFormat
                  value={profitLossValue}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={profitLossValue < 100 ? 2 : 0}
                  fixedDecimalScale={true}
                  prefix={profitLossValue >= 0 ? '+' : '-'}
                />
              </TitleLabel>

              <SubTitleLabel align="right" fontSize="11px" fontWeight="regular">
                <NumberFormat
                  value={profitLossPercentage}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={profitLossPercentage < 100 ? 2 : 0}
                  fixedDecimalScale={true}
                  prefix={profitLossPercentage >= 0 ? '+' : '-'}
                  suffix="%"
                />
              </SubTitleLabel>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      {isLastItem && <Separator />}
    </>
  );
}
