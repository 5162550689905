import useUpdateUserStatus from './useUpdateUserStatus';

const emailVerifyStatus = {
  notSet: '/emailVerify',
  verifing: '/emailVerify',
};

const phoneVerifyStatus = {
  intraUnverified: '/phoneVerify',
  interUnverified: '/phoneVerify',
};

const redirectPathForKycStatus = {
  start: '/signUpCompleted',
  needFillOutEdd: '/Edd',
  waitingForProgress: '/kycVerifying',
  kycReverify: '/kycReverify',
  end: '/kycSucceed',
  amlDenied: '/kycFailed',
  amlBlocked: '/kycFailed',
};

const bankAccountStatus = {
  unverified: '/bankAccount',
  verifing: '/bankStatus',
  failed: '/bankStatus',
};

const useRedirectPath = (pathName) => {
  const { user } = useUpdateUserStatus(pathName);
  let redirectPath = pathName || '/';
  let state = { from: pathName };

  if (!user?.isRegistered) {
    // console.log('1');
    redirectPath = '/landing';
  } else if (user?.amlStatus === 'blocked') {
    // console.log('2');
    redirectPath = redirectPathForKycStatus.amlBlocked;
  } else if (user?.amlStatus === 'denied') {
    // console.log('3');
    redirectPath = redirectPathForKycStatus.amlDenied;
  } else if (
    ['intraUnverified', 'interUnverified'].includes(user?.phoneVerifyStatus)
  ) {
    // console.log('8');
    redirectPath = phoneVerifyStatus[user?.phoneVerifyStatus];
  } else if (user?.emailVerifyStatus !== 'succeed') {
    // console.log('9');
    redirectPath = emailVerifyStatus[user?.emailVerifyStatus];
  } else if (['awaitingInput'].includes(user?.amlStatus)) {
    redirectPath = redirectPathForKycStatus.needFillOutEdd;
  } else if (user?.kycStatus === 'succeed' && user?.amlStatus === 'succeed') {
    // console.log('4');
    if (!user.bankAccount?.status) {
      //bankAccount === null || bankAccount.status === null
      // console.log('5');
      redirectPath = bankAccountStatus.unverified;
    } else if (user.bankAccount.status === 'succeed') {
      // console.log('6');
      redirectPath = pathName; // 所有狀態都通過，可以去到任何想去的頁面
    } else {
      // console.log('7');
      redirectPath = bankAccountStatus[user.bankAccount.status];
    }
  } else if (
    user?.kycStatus === 'unverified' &&
    user?.amlStatus === 'unverified'
  ) {
    // console.log('8');
    redirectPath = redirectPathForKycStatus.start;
  } else if (
    ['pendingReview', 'submmitted', 'verifing'].includes(user?.kycStatus) ||
    ['pendingReview', 'submmitted', 'verifing'].includes(user?.amlStatus)
  ) {
    // console.log('9');
    redirectPath = redirectPathForKycStatus.waitingForProgress;
  } else if (
    user?.kycStatus === 'unverified' &&
    user?.amlStatus === 'succeed'
  ) {
    redirectPath = redirectPathForKycStatus.kycReverify;
  } else {
    // console.log('10');
    redirectPath = '/KycFailed'; // 這裏應該改成權限不足要轉到告知用戶原因的頁面，EX. aml denied 後，開放資產頁，但不可儲值，但如果點擊儲值，應該怎麼做？簡單來說就是開放頁面，但不開放服務的情形
  }
  //console.log("useRedirectPath[redirectPath]=>"+redirectPath)
  //console.log("useRedirectPath[state]=>"+state)
  return { redirectPath, state };
};

export { useRedirectPath };
